@use 'sass:selector';

@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin pseudo-template() {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  background: transparent;
}

@mixin pseudo-border() {
  @include pseudo-template;
}

@mixin hover {
  &:not([disabled]):hover {
    @content;
  }
}

@mixin unify-parent($child) {
  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}

@mixin animation-mixin($name, $from, $to) {
  @keyframes #{$name} {
    0% {
      top: $from;
    }
    100% {
      top: $to;
    }
  }
}

@mixin ui-kit-modal-large {
  margin: rem(80px auto);
  height: calc(100% - 160px);
  max-height: 820px;
  padding: 0;

  @include media('<xxl') {
    margin: rem(60px auto);
    height: calc(100% - 120px);
  }

  @include media('<md') {
    margin: rem(24px auto);
    height: calc(100% - 48px);
  }

  @include media('<sm') {
    margin: rem(16px auto);
    height: calc(100% - 32px);
  }

  .ant-modal-content {
    height: 100%;
  }

  .ant-modal-body {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}
