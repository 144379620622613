@use 'sass:map';
@import 'styles/all.scss';

.ui-kit-circular-progress {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &__steps {
      position: absolute;
      font-weight: $font-weight-bold;
      padding-top: rem(2px);
    }
    &__circle {
      rotate: 90deg;

      &-track,
      &-indicator {
        stroke-width: rem(3px);
        fill: transparent;
      }

      &-track {
        stroke: map.get($colors, 'textLight', 'light');
      }

      &-indicator {
        stroke: map.get($colors, 'secondary', 'main');
        stroke-linecap: round;
      }
    }
  }
}
