@use 'sass:map';
@import 'styles/all.scss';

.ShareModal {
  &.ui-kit-modal {
    .ant-modal-body {
      padding: 2.5rem 2.5rem 0 2.5rem;
    }
  }
  width: 100% !important;
  max-width: rem(695px);

  &-messageTitle {
    margin-top: 30px;
  }
  &-messageCopyContainer {
    text-align: center;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(32px);
    .ui-kit-tabs {
      width: 100%;
    }

    @include media('<md') {
      .ui-kit-button {
        width: 100%;
      }
    }
    .spinnerContainer {
      margin: 40px;
    }
  }

  &-copyMessage {
    padding: rem(20px 20px);
    border: 1px solid map.get($colors, 'textLight', 'light');
    background-color: $color-nude;
    width: 100%;
    border-radius: rem(8px);
    margin: 15px 0;
    font-weight: bold;
    &-link {
      text-decoration: underline;
    }
  }
  &-links {
    background-color: $color-light-grey;
    width: calc(100% + 5rem);
    max-width: 100vw;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    padding: 2.5rem;
    &-header {
      text-align: center;
      font-weight: bold;
      &.ui-kit-typography {
        color: $color-primary-main;
      }
      margin-bottom: 2.5rem;
    }
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 100%;
      width: 450px;
      column-gap: 15px;
      row-gap: 10px;
      margin: auto;
      .blur {
        filter: blur(0.3rem);
      }
    }
  }
  &-link {
    flex: 1 0 21%;
    // height: 75px;
    // width: 75px;
    border-radius: 50%;
    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
    }
  }

  .howToButtonContainer {
    text-align: center;
    padding: 10px 0 30px 0;
  }
}

.howToShareContainer {
  margin: 0 10px;
  ol {
    li {
      margin: 10px 0;
    }
    ul {
      li {
        margin: 5px 10px;
      }
    }
  }
}
