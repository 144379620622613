@import 'styles/all.scss';

.Step {
  &-container {
    display: flex;
    flex-direction: column;
    gap: rem(40px);
    flex: 1;
    &-content {
      flex: 1 1 150px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    @include media('<lg') {
      gap: rem(24px);
    }
  }

  &-title {
    font-weight: $font-weight-bold;
    margin: 0px;
    @include media('<lg') {
      font-size: $font-size-large;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(16px);
    &-button__back {
      padding-left: 0px;
    }
    .ui-kit-button:last-child {
      margin-left: auto;
    }
  }
}
