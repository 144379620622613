@use 'sass:map';
@import 'styles/all.scss';

.Link {
  color: $color-primary-main;
  text-decoration: underline;
  font-weight: $font-weight-semibold;
  display: inline-flex;
  justify-content: center;
  gap: rem(8px);

  &:visited,
  &:active {
    color: map.get($colors, 'primary', 'dark');
  }

  @include hover {
    &:visited {
      color: map.get($colors, 'primary', 'main');
    }
    color: map.get($colors, 'primary', 'dark');
  }

  svg {
    width: rem(14px);
    height: rem(14px);
  }
}
