@import 'styles/all.scss';
.Integration_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(20px);
    min-width: 350px;
    
    @include media('<=md') {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .Integration_header-title {
        font-size: rem(30px);
        line-height: rem(34px);
        font-weight: $font-weight-bold;
        color: $color-title;
    }

    @include media('<=md') {
        .Integration_header-text {
            text-align: center;
        }
        .Integration_header-button {
            text-align: center;
            margin-top: rem(20px);
        }
    }
}

.Marketplace_link{
    font-weight: $font-weight-semibold;
    text-decoration: underline;
}
