@use 'sass:color';
@import 'styles/all.scss';

$box-shadow: rem(0 0 0 2px) color.change($color-primary-main, $alpha: 0.4);

.Input.ant-input {
  padding: rem(10px 14px 6px 14px);
  border-radius: rem(10px);

  &:hover,
  &:active,
  &:focus {
    border-color: $color-primary-main;
    outline-color: $color-primary-main;
    box-shadow: $box-shadow;
  }
  &:active:not(:focus) {
    box-shadow: $box-shadow;
  }
}
