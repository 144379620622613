@import 'styles/all.scss';

.request_banner-container {
  margin: 1rem auto 0rem;
  padding: 0rem 1rem;
  width: 100%;
  max-width: 90rem;
  .alert {
    width: 100%;
    .request_banner {
      width: 100%;
      padding: 1rem 1rem;
    }
  }
}