@import 'styles/all.scss';

.ProductSettingsBaseForm {
  display: flex;
  flex-direction: column;
  gap: rem(24px);
  .ui-kit-form-control {
    margin-top: 0px;

    .ui-kit-input {
      $root: &;
      &-label {
        margin-bottom: rem(16px);
      }
      &.quantity-field {
        max-width: rem(57px);
      }
      &.external-link-field {
        max-width: rem(350px);
      }
    }
  }
}

.DelegatesList {
  &__title {
    font-size: rem(14px);
    font-weight: $font-weight-semibold;
    line-height: rem(15px);
    margin-bottom: rem(16px);
  }

  &__item {
    display: flex; 
    align-items: center;
    gap: rem(16px);
    justify-content: space-between;

    margin-bottom: rem(8px);
    padding-bottom: rem(8px);
    border-bottom: 1px solid $color-gray;

    &__info {
      display: flex;
      flex-direction: column;
      gap: rem(4px);
    }
    
    &__line {
      display: flex; 
      align-items: center;
      gap: rem(24px);
    }
  }

  &__addButton {
    margin-bottom: rem(8px);

    &.mt-8 {
      margin-top: rem(16px);
    }
  }
}
