@import 'styles/all.scss';

.ui-kit-card-header {
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: rem(8px);
  padding: rem(10px 10px 0px 10px);
  p {
    margin: 0px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(8px);
    min-height: rem(65px);

    &-title {
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      margin: 0px;
      line-height: 1.25;
      min-height: rem(45px);
    }

    &-subheader {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      margin: 0px;
    }
  }
}
