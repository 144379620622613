@import 'styles/all.scss';


.EmptyData {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-secondary-text;
  flex-direction: column;

  &_icon {
    width: rem(52px);
    height: rem(52px);
  }
  
  &_label {
    font-size: rem(14px);
    font-weight: $font-weight-semibold;
  }
}