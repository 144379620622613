@import 'styles/all.scss';

.Table {
  padding: rem(30px);
  border-radius: rem(20px);
  background-color: $color-white;

  @include media('<=md') {
    padding: rem(10px);
  }

  table {
    border-spacing: rem(0 10px);
  }

  thead th {
    background-color: $color-white;
    border: none;
    // font-family: $font-spartan;
    font-size: rem(14px);
    line-height: rem(24px);
  }

  tbody tr {
    background-color: $color-light-grey;
  }

  tbody td:first-child {
    border-radius: rem(10px 0 0 10px);
  }
  tbody td:last-child {
    border-radius: rem(0 10px 10px 0);
  }

  .ant-table-pagination .ant-pagination-item {
    border: none;
    border-radius: rem(5px);
    // border-radius: 0;
    // font-family: $font-spartan;
    font-weight: $font-weight-semibold;
    font-size: rem(16px);

    &:hover,
    &:active,
    &:focus {
      border: rem(1px) solid $color-main-gray;
    }
  }
  // .ant-table-pagination .ant-pagination-item {
  //   &:hover a,
  //   &:active a,
  //   &:focus a {
  //     border: rem(1px) solid $color-white;
  //   }
  // }
  .ant-table-pagination .ant-pagination-prev button,
  .ant-table-pagination .ant-pagination-next button {
    border: none;
    border-radius: rem(5px);

    &:hover,
    &:active,
    &:focus {
      border: rem(1px) solid $color-main-gray;
    }
  }
}
