@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

.ui-kit-image-upload {
  &-zone {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $color-nude;
    border-radius: rem(8px);
    height: rem(210px);
    width: rem(240px);
    border: 1px dashed map.get($colors, 'textLight', 'light');
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    .ant-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
    }

    &:hover {
      $color-primary-main: map.get($colors, 'primary', 'main');
      color: $color-primary-main;
      border-color: $color-primary-main;
      svg {
        color: color.change($color-primary-main, $alpha: 0.5);
      }
    }

    &-preview {
      border-radius: rem(8px);
      width: 100%;
      height: auto;
      aspect-ratio: 1;
      object-fit: cover;
      object-position: center;
      // @include media('<=md') {
      //   width: rem(120px);
      //   height: rem(120px);
      // }
    }

    svg {
      height: rem(100px);
      width: rem(100px);
      color: map.get($colors, 'textLight', 'dark');
      transition: all 0.3s ease-in-out;
    }
  }
}

// .img-crop-control {
//   .ant-slider {
//     .ant-slider-handle {
//       border-color: color.change($color-primary-main, $alpha: 0.8);
//     }
//     .ant-slider:hover {
//       .ant-slider-handle {
//         border-color: $color-primary-main;
//       }
//     }
//   }
//   .ant-slider {
//     .ant-slider-track {
//       background-color: color.change($color-primary-main, $alpha: 0.8);
//     }
//   }
//   .ant-slider:hover {
//     .ant-slider-track {
//       background-color: $color-primary-main;
//     }
//     .ant-slider-handle:not(.ant-tooltip-open) {
//       border-color: $color-primary-main;
//     }
//   }
// }
