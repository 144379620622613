@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

.ui-kit-dropdown {
  @at-root .ant-dropdown {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: rem(8px);
    overflow: hidden;
    .ant-menu-item {
      &.ant-menu-item-only-child {
        font-size: $font-size-small;
        font-weight: $font-weight-semibold;
        color: map.get($colors, 'textDark', 'main');
        $color-primary-main: map.get($colors, 'primary', 'main');
        position: relative;
        min-width: rem(100px);
      }

      &-active {
        background-color: color.change($color-primary-main, $alpha: 0.1);
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-right: 3px solid $color-primary-main;
        }
      }

      &-selected {
        background-color: color.change($color-primary-main, $alpha: 0.1) !important;
      }
    }
  }
}
