@use 'sass:color';
@import 'styles/all.scss';

.ui-kit-input-rules {
  margin-left: rem(10px);
  margin-top: rem(10px);
  display: flex;
  flex-direction: column;

  .ui-kit-input-rules-header {
    margin-bottom: rem(8px);
  }
  .ui-kit-input-rules-header,
  .ui-kit-input-rules-body {
    padding-left: rem(6px);
    color: $color-gray-4;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    display: flex;
    flex-direction: column;

    .ui-kit-input-rule,
    .ui-kit-input-rule-valid {
      display: flex;
      align-items: center;
      .ui-kit-input-rule-message {
        padding: rem(5px) rem(4px);
      }
    }

    .ui-kit-input-rule {
      .ui-kit-input-rule-icon {
        visibility: hidden;
      }
    }
  }
}
