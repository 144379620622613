@use 'sass:map';
@import 'styles/all.scss';

@mixin get-badge-by-color($root) {
  $array-colors: 'primary', 'secondary', 'error', 'success', 'warning';
  @each $color in $array-colors {
    #{$root + '-' + $color} {
      background-color: map.get($colors, $color, 'main');
      color: map.get($colors, 'white');
    }
  }
}

.ui-kit-badge {
  $root: &;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;
  @include get-badge-by-color($root);

  &-standard {
    display: flex;
    flex-flow: row wrap;
    place-content: center;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1;
    z-index: 1;

    min-width: 20px;
    padding: 0px 6px;
    height: 20px;
    border-radius: 10px;

    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 0px;
    right: 0px;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
  }

  &-dot {
    @extend #{$root}-standard;
    min-width: 8px;
    padding: 0px;
    height: 8px;
    border-radius: 6px;
  }

  &-invisible {
    transform: scale(0) translate(50%, -50%);
  }
}
