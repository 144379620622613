@import 'styles/all.scss';

.DashboardFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: rem(20px) rem(15px) rem(50px);
  font-size: $font-size-small;
  @include media('<=md') {
    padding: rem(10px);
    padding-top: rem(25px);
    padding-right: rem(25px);
  }

  .DashboardFooter_link {
    &:last-child {
      margin-left: rem(25px);
    }
  }
}
