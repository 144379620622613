@use 'sass:color';
@use 'sass:map';
@use 'sass:string';

@import 'styles/all.scss';

@mixin get-button-by-type($root) {
  $array-colors: 'primary', 'secondary', 'success', 'warning', 'error', 'textDark', 'textLight',
    'textGray';
  @each $color in $array-colors {
    $color-main: map.get($colors, $color, 'main');
    $color-disabled-text: map.get($colors, 'textLight', 'dark');
    $color-disabled-bg: map.get($colors, 'textLight', 'light');

    #{if(&, '&.ui-kit-button-contained', '')} {
      @include unify-parent($root + '-' + $color) {
        color: $color-white;
        background-color: $color-main;
        @include hover {
          color: $color-main;
          background-color: transparent;
          border: 1px solid $color-main;
        }
        &:disabled {
          color: $color-disabled-text;
          background-color: $color-disabled-bg;
        }
      }
    }

    #{if(&, '&.ui-kit-button-outlined', '')} {
      @include unify-parent($root + '-' + $color) {
        background-color: transparent;
        border: 1px solid color.change($color-main, $alpha: 0.5);
        color: $color-main;
        @include hover {
          border: 1px solid transparent;
          background-color: $color-main;
          color: $color-white;
        }
        &:disabled {
          color: $color-disabled-text;
          border: 1px solid color.change($color-disabled-text, $alpha: 0.5);
        }
      }
    }

    #{if(&, '&.ui-kit-button-text', '')} {
      @include unify-parent($root + '-' + $color) {
        background-color: transparent;
        color: $color-main;
        font-size: inherit;
        @include hover {
          color: map.get($colors, $color, 'dark');
        }
        &:disabled {
          color: $color-gray-2;
        }
      }
    }
  }
}

.ui-kit-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: $font-weight-semibold;
  font-size: $font-size-small;
  line-height: 1.75;
  letter-spacing: rem(0.1px);

  min-width: rem(64px);
  max-height: rem(46px);
  border-radius: rem(10px);
  transition: all 0.25s ease-in-out;

  &:disabled {
    cursor: default;
  }

  $root: &;

  &-contained {
    @include get-button-by-type($root);
  }

  &-outlined {
    @include get-button-by-type($root);
  }

  &-text {
    @include get-button-by-type($root);
  }

  &-icon {
    border-radius: 50%;
    min-width: rem(32px);

    &#{$root}-small {
      padding: rem(6px);
    }

    &#{$root}-medium {
      padding: rem(8px);
    }

    &#{$root}-large {
      padding: rem(10px);
    }
  }

  &__icon {
    display: inherit;
    margin-bottom: rem(2px);
    &-start {
      order: 0;
      margin-right: rem(8px);
    }
    &-end {
      order: 1;
      margin-left: rem(8px);
    }
  }

  &-small {
    font-size: $font-size-caption;
    padding: rem(8px 20px 6px);
    svg {
      width: rem(18px);
      height: rem(18px);
    }
  }

  &-medium {
    font-size: $font-size-small;
    padding: rem(12px 24px 10px);
    svg {
      width: rem(20px);
      height: rem(20px);
    }
  }

  &-large {
    font-size: $font-size-regular;
    padding: rem(12px 26px 10px);
    svg {
      width: rem(22px);
      height: rem(22px);
    }
  }

  &-fullWidth {
    width: 100%;
  }
}
