@import 'styles/all.scss';

.MasqueradingBanner {
  top: 0;
  left: 0;
  color: $color-title;
  background-color: $color-light-error;
  width: 100%;
  height: rem(35px);
  border: rem(1px) solid $color-red;
  box-shadow: inset rem(0 0 10px) $color-red;
  // font-family: $font-spartan;
  font-size: rem(14px);
  line-height: rem(22px);
  font-weight: $font-weight-semibold;
  padding: rem(6px 20px);
  z-index: 1080;
  position: fixed;
}
