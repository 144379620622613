@use 'sass:map';
@import 'styles/all.scss';

.SelectProduct {
  position: relative;
  &-filter {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: map.get($colors, 'white');
  }
}
