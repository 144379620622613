@import 'styles/all.scss';

.dropdown-btn > .ant-btn  {
    height: 57.5px;
    background-color: $color-primary-main !important;
    color: $color-white !important;
    border-color: $color-primary-main;
    font-weight:$font-weight-semibold;
    
    &:hover{
        background-color: $color-white !important;
        color: $color-primary-main !important;
        border-color: $color-primary-main;
    }
    &:disabled{
        background-color: $color-gray !important;
        color: $color-line-divider !important;
        border: unset;
        cursor: unset;
        &:hover{
            background-color: $color-gray !important;
            color: $color-line-divider !important;
            cursor: unset;
        }
    }

  }

  