@use 'sass:color';

// !This colors dont make sense as visualy its imposible to see the difference
$color-nude: #f5f5f5;
$color-light-gray-2: #e6e6e6; // This is the same as $color-gray
$color-light-grey: #fafafa;
$color-light-gray: #f1edf3;
$color-disabled-item: #f6f6f6; // this doestn have to much contrast to be a disabled state

/////////////////////////////////////////////////////////////////////////////////////
$color-body-1: #272527; // color-textDark-dark
$color-main-gray: #3a383b; // color-textDark-main
$color-title: #484549; //color-textDark-light

$color-secondary-text: #959097; // color-textGray-light
$color-gray-4: #807e82; // color-textGray-main
$color-gray-3: #777578; // color-textGray-dark

$color-line-divider: #b3b0b5; // color-textLight-dark
$color-gray-2: #c7c2ca; // color-textLight-main
$color-gray: #e5e2e7; // color-textLight-light

$color-white: #ffffff; // color-textLight-light
$color-background: #ffffff;

// $color-green: #64871b;

// $color-light-green: #e8f1d3;
// $color-error: #9e1717;
$color-light-error: #edcdcd;
$color-warning: #fceecb;
$color-warning-text: #947016;

// New colors
// $color-light-gray: #f1edf3;
// $color-teal: #2da397;
// $color-light-teal: #cbebe8;
// $color-line-divider: #d9d0dd;
// $color-light-gray-2: #e6e6e6;

$color-primary-main: #176b63;
$color-secondary-main: #1d7499;
$color-primary-light: lighten($color-primary-main, 33.2%);
// $color-success:
// $color-warning:
$color-red: #ff0000;
$color-green: #64871b;
$color-yellow: #947016;

$colors: (
  'primary': (
    'main': $color-primary-main,
    'light': lighten($color-primary-main, 33.2%),
    'dark': darken($color-primary-main, 12%),
  ),
  'secondary': (
    'main': $color-secondary-main,
    'light': lighten($color-secondary-main, 33.2%),
    'dark': darken($color-secondary-main, 12%),
  ),
  'success': (
    'main': #4caf50,
    'light': lighten(#4caf50, 33.2%),
    'dark': darken(#4caf50, 12%),
  ),
  'warning': (
    'main': #ffeb3b,
    'light': lighten(#ffeb3b, 33.2%),
    'dark': darken(#ffeb3b, 12%),
  ),
  'error': (
    'main': #ff0000,
    'light': lighten(#ff0000, 33.2%),
    'dark': darken(#ff0000, 12%),
  ),
  'textDark': (
    'main': #3a383b,
    'light': #484549,
    'dark': #272527,
  ),
  'textGray': (
    'main': #807e82,
    // secondary text
    'light': #959097,
    'dark': #777578,
  ),
  'textLight': (
    'main': #c7c2ca,
    // button inactive
    'light': #e5e2e7,
    // subtitle secondary
    'dark': #b3b0b5,
  ),
  'white': #ffffff,
);
