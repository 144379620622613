@import 'styles/all.scss';

.alert_type_warning {
  text-align: left;
}

.alert_type_warning .ant-alert-warning {
  background-color: $color-warning;
  padding: rem(12px 27px 10px 20px);
  border: 0;
}

.alert_type_warning .ant-btn-link {
  color: $color-primary-main;
  text-decoration: underline;
}

.alert_type_warning .ant-alert-message {
  color: $color-warning-text;
  font-size: rem(14px);
  line-height: rem(26px);
  // font-family: $font-spartan;
}
