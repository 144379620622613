@use 'sass:map';
@import 'styles/all.scss';

@mixin get-spinner-by-size($root) {
  $array-colors: (
    'small': 3px,
    'medium': 4px,
    'large': 4px,
  );

  @each $size, $size-px in $array-colors {
    @include unify-parent($root + '-' + $size) {
      @if $size == 'large' {
        height: rem(28px);
        width: rem(28px);
      } @else if $size == 'medium' {
        height: rem(24px);
        width: rem(24px);
      } @else {
        height: rem(20px);
        width: rem(20px);
      }
      border-width: $size-px;
      &:after {
        top: -$size-px;
        left: -$size-px;
        bottom: -$size-px;
        right: -$size-px;
        border-width: $size-px;
      }
    }
  }
}

.ui-kit-spinner {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  border-style: solid;
  border-color: map.get($colors, 'textLight', 'main');
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border-style: solid;
    border-color: transparent;
    border-top-color: map.get($colors, 'textLight', 'dark');
    -webkit-animation: spin 0.7s linear infinite;
    -o-animation: spin 0.7s linear infinite;
    animation: spin 0.7s linear infinite;
  }

  @include get-spinner-by-size(&);
}

@-webkit-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
