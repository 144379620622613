@import 'styles/all.scss';

.Header {
  background-color: $color-background !important;
  height: auto !important;
  border-bottom: rem(5px) solid $color-title;

  @include media('<=md') {
    border-bottom: rem(2px) solid $color-title;
  }

  .Header_logo {
    padding-top: rem(15px);
    padding-bottom: rem(15px);
    margin: auto;
    width: fit-content;
  }
}
