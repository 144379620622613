@import '~sass-rem';

// Font-weight
$font-weight-light: 400;
$font-weight-regular: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Font-size
$font-size-caption: rem(12px);
$font-size-small: rem(14px);
$font-size-regular: rem(16px);
$font-size-large: rem(18px);
