@import 'styles/all.scss';

.LoadingModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .LoadingModal_content {
    width: rem(343px);
    height: rem(394px);
    margin: rem(60px 16px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    text-align: center;

    .LoadingModal_title {
      font-weight: $font-weight-bold;
      font-size: rem(30px);
      line-height: rem(34px);
      // font-family: $font-helvetica;
    }

    .LoadingModal_copy {
      font-weight: $font-weight-regular;
      font-size: rem(14px);
      line-height: rem(24px);
      // font-family: $font-spartan;
    }

    .LoadingModal_loading {
      margin: rem(15px, auto);

      .LoadingModal_spinner {
        width: 80px;
        height: 80px;
        display: inline-block;
        position: relative;
        border-radius: 50px;
        border: 7px solid $color-light-gray;
      }

      .LoadingModal_spinner:after {
        content: '';
        position: absolute;
        top: -6px;
        left: -6px;
        bottom: -6px;
        right: -6px;
        border-radius: 50px;
        border: 7px solid transparent;
        border-top-color: $color-primary-main;
        -webkit-animation: spin 0.7s linear infinite;
        -o-animation: spin 0.7s linear infinite;
        animation: spin 0.7s linear infinite;
      }

      /* Animation */
      @-webkit-keyframes spin {
        0% {
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }
  }
}
