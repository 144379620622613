@import 'styles/all.scss';
.ImportStores_table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 350px;

    .ImportStores_table_contents {
        width: 100%;
        row-gap: rem(4px);
        display: flex;
        .ant-form {
            flex: 1;
        }
        .EmptyData {
            margin-top: rem(16px);
        }
    }

    .ImportStores_form {
        width: 100%;
    }

    .ImportStores_table_row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        white-space: nowrap;
        padding: 0 rem(12px);

        @include media('<=md') {
            align-items: flex-start;
        }

        &.header-row {
            min-height: rem(33px);
            border: none;
            background: $color-light-gray-2;
            font-weight: $font-weight-semibold;
            font-size: rem(12px);
            line-height: rem(24px);
            @include media('<=md') {
                background: $color-white;
                .header-col:not(.checkbox) {
                    display: none;
                }
            }
        }
        &.contents-row {
            min-height: rem(72px);
            border-left: 1px solid $color-light-gray-2;
            border-right: 1px solid $color-light-gray-2;
            border-bottom: 1px solid $color-light-gray-2;

            &:first-child {
                border-top: 1px solid $color-light-gray-2;
            }

            @include media('<=md') {
                border-left: none;
                border-right: none;

                &:first-child {
                    border-top: none;
                }
            }
        }

        .ImportStores_table_col_group {
            display: flex;
            flex: 8;
            flex-wrap: wrap;
            .ImportStores_table_col.name {
                @include media('<=md') {
                    flex: 0 0 100%;
                }
            }
            &.header-group {
                @include media('<=md') {
                    display: none;
                }
            }
        }
    }

    .ImportStores_table_col {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        margin: rem(0 2px);
        padding-left: rem(12px);

        &.header-col {
            min-height: rem(33px);

            .ant-checkbox-wrapper {
                font-size: rem(12px);
                line-height: rem(24px);
            }
        }

        &.contents-col {
        min-height: rem(60px);

        .ant-form-item {
            font-size: rem(12px);
            line-height: rem(24px);
            margin: auto 0;

            .ant-input {
                width: rem(260px);
                font-size: rem(12px);
                line-height: rem(24px);
                }
            }
        }

        &.checkbox {
            flex: 2;

            @include media('<=md') {
                flex: 1;
            }
        }

        &.photo {
            flex: 2;

            @include media('<=md') {
                flex: 1;

                img {
                    width: rem(60px);
                    height: rem(40px);
                }
            }
        }

        &.name {
            flex: 4;
        }

        &.city {
            flex: 2;
        }
        @include media('<=md') {
            &.city, &.state, &.zip {
                flex: 0;
            }
        }
    }
}