@import 'styles/all.scss';

.hc-settings {
  min-width: rem(270px);
  position: relative;
  // padding-bottom: rem(50px);

  .hc-wrapper {
    border-radius: revert;
    box-shadow: revert;
  }

  &.subform {
    top: 0;
    margin: rem(12px 12px);
    // padding: rem(24px 32px);
    max-width: rem(375px);

    .hc-wrapper__title {
      font-size: rem(20px);
      font-weight: $font-weight-bold;
    }

    .inline {
      display: inline;
      width: min-content;
    }
  }

  &__description {
    & .ant-input-textarea-show-count {
      position: relative;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: rem(-31px);
        color: $color-body-1;
        // font-family: $font-spartan;
        font-size: rem(14px);
        line-height: rem(24px);
        letter-spacing: 0em;
        text-align: center;
        @include media('>md') {
          position: relative;
          float: left;
          margin-top: rem(5px);
        }
        @include media('>xl') {
          position: absolute;
          margin-top: rem(-31px);
          display: block;
        }
      }
    }
  }
  &__submit {
    position: absolute;
    top: rem(-69px);
    right: 0;

    @include media('<lg') {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: rem(10px);
      background: rgba(240, 239, 234, 0.5);
      backdrop-filter: blur(15px);
      top: auto;
      bottom: 0;
      z-index: 3;
    }

    .Button {
      height: rem(45px);
    }
  }
  &__button-row {
    display: flex;
    gap: rem(25px);
    .Button {
      height: rem(45px);
    }
  }
  &__pseudo-password {
    letter-spacing: 6px;
    padding: rem(10px 0 20px);
  }

  .hc-delete-account {
    width: fit-content;
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    gap: rem(24px);
  }

  .ant-form-item-label label {
    font-size: rem(13px);
    font-weight: $font-weight-semibold;
    line-height: rem(15px);
  }
}

.hc-settings_footer {
  padding-right: rem(32px);
  min-width: fit-content;

  @include media('<=md') {
    padding-top: rem(15px);
    padding-right: rem(7px);
  }
}

.ant-modal-body {
  .ArchiveStore_modalTitle {
    text-align: center;
  }

  .ArchiveStore_modalBody {
    font-size: rem(14px);
    line-height: rem(24px);
    font-family: 'Spartan';
    font-weight: 400;

    .ArchiveStore_supportEmail {
      color: $color-primary-main;
      font-weight: $font-weight-semibold;
    }
  }
}

.hc-settings__modal {
  max-width: rem(315px);

  .ant-modal-content {
    padding: rem(50px 20px);

    .ant-modal-body {
      text-align: center;
      font-size: rem(13px);
      line-height: rem(20px);

      .Modal_head {
        text-align: center;
        font-size: rem(18px);
        line-height: rem(24px);
        padding-bottom: rem(10px);
      }
    }
  }
}


