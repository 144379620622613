@use 'sass:map';
@import 'styles/all.scss';

.ui-kit-form-control {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px;
  vertical-align: top;
  transition: all 0.3 ease-in-out;

  &-error {
    color: map.get($colors, 'error', 'main');
  }

  &-fullWidth {
    width: 100%;
  }

  &:not(:first-child) {
    margin-top: rem(16px);
  }
}
