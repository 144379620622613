@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

@mixin get-color-collapse($root) {
  $array-colors: 'primary', 'secondary', 'success', 'warning', 'error', 'textDark', 'textLight',
    'textGray';
  @each $color in $array-colors {
    $color-main: map.get($colors, $color, 'main');
    @include unify-parent($root + '-' + $color) {
      background-color: color.change($color-main, $alpha: 0.05);
    }
  }
}

.ui-kit-collapse {
  width: 100%;
  border-radius: rem(4px);
  overflow: hidden;

  @include get-color-collapse(&);

  &-header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    outline: 0px;
    border: none;
    cursor: pointer;
    user-select: none;
    min-height: rem(72px);
    width: 100%;
    padding: rem(12px 20px);
    gap: rem(4px);

    @include media('<md') {
      padding: rem(6px 12px);
    }

    &__icon {
      transition: rotate 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      color: map.get($colors, 'textGray', 'dark');
      &-active {
        rotate: 180deg;
      }
    }
  }

  &-content {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &-wrapper {
      padding: rem(0px 24px 12px);
      @include media('<md') {
        padding: rem(0px 12px 12px);
      }
    }
  }
}
