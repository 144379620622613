@use 'sass:map';
@import 'styles/all.scss';

.DashboardSales {
  max-width: map.get($breakpoints, 'xxl');
  margin: 0 auto;
  .DashboardSales_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: rem(38px);
  }

  .DashboardSales_title {
    font-size: rem(30px);
    line-height: rem(34px);
    // font-family: $font-helvetica;
    font-weight: $font-weight-bold;
    margin: 0;
  }
}
