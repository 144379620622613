@import 'styles/all.scss';

.ui-kit-modal {
  &-title {
    font-weight: $font-weight-bold;
    margin-bottom: rem(24px);
    @include media('<md') {
      font-size: $font-size-large;
    }
  }

  .ant-modal-body {
    padding: rem(40px);
    @include media('<md') {
      padding: rem(16px);
    }
  }

  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media('<lg') {
    max-width: rem(554px) !important;
  }

  @include media('<md') {
    max-width: calc(100% - 16px) !important;
  }
}
