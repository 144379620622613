@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

.ui-kit-menu {
  &-item {
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    color: map.get($colors, 'textDark', 'main');
    position: relative;
    min-width: rem(100px);
    display: flex;
    align-items: center;
    height: rem(40px);
    transition: all 0.3s ease-in-out;

    &.ant-menu-item-active,
    &.ant-dropdown-menu-item-active {
      background-color: color.change(map.get($colors, 'primary', 'main'), $alpha: 0.1);
      &::after {
        content: '';
        position: absolute;
        width: rem(3px);
        background-color: map.get($colors, 'primary', 'main');
        left: 0;
        top: 0;
        height: 100%;
      }
    }
  }
}
