@import 'styles/all.scss';

.StoreModal {
  width: 100% !important;
  max-width: rem(954px);

  @include media('<=md') {
    max-width: rem(650px);
  }

  @include ui-kit-modal-large();
}
