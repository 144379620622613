@import 'styles/all.scss';

.SignupPage {
  &-collapsable {
    &__content {
      display: flex;
      align-items: flex-end;
      gap: rem(14px);
    }
    .ui-kit-button:first-child {
      padding: 0px;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
  }

  &-social-login {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
  }

  &-agreement-modal {
    max-width: rem(315px);
    &__content {
      display: flex;
      gap: rem(14px);
    }
  }
  &-singin-button {
    padding: 0px;
  }
}
