@use 'sass:map';
@import 'styles/all.scss';

.TopNav {
  position: fixed;
  height: rem(70px);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $color-body-1;
  z-index: 20;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;

  @include media('<=md') {
    height: rem(56px);
  }

  $color-primary-light: map.get($colors, 'primary', 'light');

  &.masquerading {
    top: rem(35px);
  }

  .TopNav_brand {
    margin-right: rem(16px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    @include media('<=md') {
      display: none;
    }

    .TopNav_logoAsIcon {
      width: rem(35px);
      height: rem(35px);
      margin: 0 rem(8px);
    }

    .TopNav_logoAsText {
      margin-top: rem(15px);
      width: rem(120px);
      height: rem(20px);
    }
  }

  .TopNav_content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    flex: 1;
    padding: rem(0 16px);

    @include media('<=md') {
      justify-content: space-between;
    }

    .TopNav_logoAsIcon {
      width: rem(30px);
      height: rem(30px);
      @include media('>md') {
        display: none;
      }
    }

    .ant-menu {
      background-color: $color-body-1;
      border-bottom: 0 !important;
      &-horizontal {
        .ant-menu-submenu {
          margin-right: rem(40px);
        }

        .ant-menu-submenu,
        .ant-menu-sub,
        .ant-menu-item {
          border-bottom: 0 !important;
        }

        .ant-menu-item,
        .ant-menu-submenu {
          color: $color-white;
          font-weight: $font-weight-regular;
          transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
          .ant-menu-submenu-arrow {
            display: block;
            color: $color-white;
            left: 100%;
            margin-left: rem(12px);
            &::before {
              transform: rotate(-45deg) translateX(2.5px);
            }
            &::after {
              transform: rotate(45deg) translateX(-2.5px);
            }
          }
          &-selected {
            color: $color-primary-light;
            font-weight: $font-weight-bold;
            .ant-menu-submenu-arrow {
              color: $color-primary-light;
            }
          }
          &-active {
            color: $color-primary-light !important;

            .ant-menu-submenu-title {
              color: $color-primary-light;
            }
          }
          &-open {
            .ant-menu-submenu-title {
              color: $color-white;
            }
            .ant-menu-submenu-arrow {
              &::before {
                transform: rotate(45deg) translateX(2.5px);
              }
              &::after {
                transform: rotate(-45deg) translateX(-2.5px);
              }
            }
          }
        }
        @at-root .ant-menu-submenu.ant-menu-submenu-popup {
          top: rem(60px) !important;
          .ant-menu {
            .ant-menu-item-divider {
              background-color: $color-secondary-text;
            }
            &.ant-menu-sub.ant-menu-vertical {
              background-color: $color-main-gray;
              .ant-menu-item {
                color: $color-white;
                font-weight: $font-weight-regular;
                background-color: $color-main-gray;
                display: flex;
                align-items: center;
                &-selected {
                  color: $color-primary-light;
                  background-color: rgba(128, 126, 130, 0.4);
                }
                &-active {
                  background-color: rgba(128, 126, 130, 0.4);
                }
              }
            }
          }
        }
      }
    }

    &-primary {
      flex: 1;
      .ant-menu {
        @include media('<=md') {
          display: none;
        }
      }
      @include media('<=md') {
        flex: 0;
      }
    }

    &-secondary {
      flex: 0;
      .ant-menu {
        display: flex;
        .ant-menu-submenu {
          margin-right: rem(20px);
        }
        @at-root .ant-menu-submenu.ant-menu-submenu-popup {
          #account\$Menu {
            transform: translateX(25px);
          }
        }
      }
      .TopNav_accountMenu {
        display: flex;
        gap: rem(12px);
        align-items: center;
        &-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          height: rem(32px);
          width: rem(32px);
          border-radius: 50%;
          color: map.get($colors, 'secondary', 'main');
          background-color: #f0efea;
          & > span {
            font-weight: $font-weight-semibold;
            font-size: rem(16px);
            margin-top: rem(4px);
          }
        }
        &-name-block {
          display: flex;
          flex-direction: column;
          line-height: rem(24px);
          align-items: flex-end;
          margin-right: rem(16px);
          @include media('<=md') {
            display: none;
          }

          & > span {
            font-weight: $font-weight-semibold;
            font-size: rem(16px);
            line-height: rem(24px);
          }
          &_plan {
            font-size: rem(6px);
            position: relative;
            transform: none;
            height: rem(12px);
            text-transform: uppercase;
            padding-top: 1px;
          }
          .ui-kit-badge {
            line-height: 12px;
            justify-content: flex-end;
          }
        }
      }
    }

    &-burgerMenuButton {
      margin: 0;
      width: fit-content;
      padding: rem(12px);
      @include media('>md') {
        display: none;
      }
    }
  }

  .TopNav_drawer {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    visibility: hidden;

    &.show {
      visibility: initial;
      .TopNav_drawer_container {
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        left: 0;
      }
    }

    .TopNav_drawer_container {
      position: fixed;
      top: 0;
      padding: 0;
      margin: 0;
      width: 375px;
      height: 100vh;
      background-color: $color-main-gray;
      display: flex;
      flex-direction: column;
      overflow: auto;
      left: -375px;
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

      .DashboardHeader {
        .controlText {
          color: $color-primary-light;
        }
        &_control {
          svg {
            color: $color-primary-light;
          }
        }
      }
      .ant-tabs {
        flex: 1;
        .ant-tabs-content,
        .ant-row,
        .ant-col,
        .ant-menu {
          height: 100%;
          .menu-item-pro-upgrade {
            position: absolute;
            bottom: 0;
          }
        }
      }
      .ant-tabs-tab {
        color: $color-white;
        font-weight: $font-weight-semibold;
        &-disabled {
          color: $color-gray-4;
          pointer-events: none;
        }
        &-active {
          .ant-tabs-tab-btn {
            color: $color-primary-light;
            font-weight: $font-weight-bold;
          }
        }
      }

      .ant-tabs-ink-bar {
        background: $color-primary-light;
      }

      .ant-menu {
        border: 1px solid $color-main-gray;
        .ant-menu-item-only-child {
          padding-left: rem(60px) !important;
          & > button {
            justify-content: flex-start;
            color: $color-white;
          }
        }
        .ant-menu-item-divider {
          background-color: $color-secondary-text;
        }
      }

      .ant-menu,
      .ant-menu-submenu,
      .ant-menu-item {
        background-color: $color-main-gray;
      }

      .ant-menu-item,
      .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        color: $color-white;
        height: rem(50px);
        & > svg {
          margin-right: rem(18px);
        }
        & > .ant-menu-submenu-arrow {
          color: $color-white;
        }
      }

      .ant-menu-item,
      .ant-menu-submenu {
        color: $color-white;
        font-weight: $font-weight-semibold;
        & > svg {
          margin-right: rem(18px);
        }
        .hc-deactivate-store {
          padding: 0px !important;
        }
        &-selected {
          color: $color-primary-light;
          background-color: rgba(128, 126, 130, 0.4);
          font-weight: $font-weight-bold;
          .ant-menu-submenu-title,
          .ant-menu-submenu-arrow {
            color: $color-primary-light;
          }
        }
        &-open {
          background-color: transparent;
        }
        &::after {
          left: 0;
          right: unset;
        }
      }
      &-closeButton {
        margin: 0;
        margin-right: auto;
        width: fit-content;
        box-shadow: none;
      }
    }

    .TopNav_drawer_backdrop {
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(5px);
      position: absolute;
    }
  }
}
