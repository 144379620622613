@import 'styles/all.scss';

.CardList {
  &-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: rem(40px);
    place-content: center;
    padding: rem(16px 12px);
    :only-child, :last-child:nth-child(odd) {
      grid-column: 1 / -1;
      justify-self: center;
      max-width: rem(450px);
    }
    @include media('<lg') {
      grid-template-columns: 1fr;
      gap: rem(12px);
    }

    @include media('<sm') {
      gap: rem(8px);
    }

    .ant-divider {
      grid-column: 1/-1;
      &::before,
      &::after {
        top: 0px;
      }
    }
  }
}
