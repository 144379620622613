@use 'sass:map';
@import 'styles/all.scss';

.ui-kit-card {
  display: flex;
  flex-direction: column;
  border-radius: rem(10px);
  box-shadow: rem(4px 4px 25px) rgba(174, 174, 174, 0.25);
  background-color: map.get($colors, 'white');
  position: relative;
  overflow: hidden;
  &-role-button {
    cursor: pointer;
  }
}
