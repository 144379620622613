@use 'sass:color';
@use 'sass:map';
@import 'styles/all.scss';

.ui-kit-checkbox.ant-checkbox-wrapper {
  &:hover,
  &:hover .ant-checkbox-inner {
    border-color: $color-primary-main;
  }

  .ant-checkbox-inner {
    width: rem(25px);
    height: rem(25px);
    margin-right: rem(8px);
    border: 1px solid map.get($colors, 'textLight', 'dark');
    box-sizing: border-box;
    border-radius: rem(8px);
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: transparent;
      &:after {
        width: rem(15px);
        height: rem(15px);
        color: map.get($colors, 'textDark', 'dark');
        content: url('./icon.svg');
        -webkit-transform: rotate(0deg) scale(1) translate(0, -35%);
        transform: rotate(0deg) scale(1) translate(0, -35%);
        border: none;
      }
    }
    &:after {
      border: none;
    }
  }
}
