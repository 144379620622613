@import 'styles/all.scss';

.ui-kit-card-content {
  display: flex;
  flex-direction: column;
  padding: rem(10px);
  gap: rem(18px);
  p {
    margin: 0px;
  }
}
