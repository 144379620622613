@import 'styles/all.scss';

.DelegateModal{
    text-align: left;
    &_title{
      font-weight: $font-weight-bold;
      margin-bottom: 2rem;
    }

    .email-label label,
    .phone-number-label label {
      font-size: rem(13px);
      font-weight: $font-weight-semibold;
      line-height: rem(15px);
    }

   
    &_list_notifications{
        width: 100%;
        margin-bottom: 1rem;
        
        .label-title{
          height: rem(24px);
          font-size: rem(13px);
          font-weight: $font-weight-semibold;
          line-height: rem(15px);
          margin-bottom: 0.5rem;
        }
    }
    .ui-kit-checkbox{
        display: flex;
        flex-direction: row;
        align-items: center;
    } 
      
  }

  