.orders-table {
    width: 100%;
    white-space: nowrap;
    padding: rem(0px);

    table {
      border-spacing: 0;
    }
  }

.guest-name-cell, .store-name-cell {
    max-width: rem(373px);
  }

  .DashboardSales_subtable {
    padding-top: 0;
    padding-bottom: 0;
    table {
      border-spacing: 0 10px;
    }
    .ant-table {
      margin: 0px !important;
    }
  }

  th {
    font-weight: bolder !important;
  }