@use 'sass:map';
@import 'styles/all.scss';

.ui-kit-input-adornment {
  display: flex;
  height: 0.01em;
  max-height: 2em;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  color: map.get($colors, 'textGray', 'light');
  font-weight: $font-weight-regular;
  p {
    margin: 0px;
    line-height: 1;
  }
}
