@use 'sass:map';
@import 'styles/all.scss';

@mixin get-typography-by-color($root) {
  $array-types: (
    'info': 'textDark',
    'error': 'error',
  );
  @each $type, $color in $array-types {
    @include unify-parent($root + '-' + $type) {
      color: map.get($colors, $color, 'main');

      #{if(&, '&.ui-kit-input-helper-text-error', '')} {
        font-size: $font-size-caption;
        #{$root}-icon {
          background-color: map.get($colors, $color, 'main');
        }
      }

      #{if(&, '&.ui-kit-input-helper-text-info', '')} {
        font-size: $font-size-small;
        #{$root}-icon {
          background-color: map.get($colors, 'secondary', 'main');
        }
      }
    }
  }
}

.ui-kit-input-helper-text {
  font-weight: $font-weight-regular;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  display: flex;
  align-items: flex-start;
  margin: rem(12px 0px 0px);
  letter-spacing: 0.00938em;

  @include get-typography-by-color(&);

  &-icon {
    padding: rem(2px);
    svg {
      height: $font-size-small;
      width: $font-size-small;
    }
  }

  &-icon {
    display: inline-flex;
    border-radius: 50%;
    color: map.get($colors, 'white');
    margin-right: rem(4px);
  }
}
