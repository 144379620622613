@import 'styles/all.scss';

.UnauthLayout {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: rem(48px);
  &-container {
    flex: 1;

    &__content {
      flex: 1;
      width: rem(423px);
      margin: 0 auto;

      @include media('<md') {
        width: 100%;
        padding: rem(0px 16px);
      }

      &-card {
        padding: rem(16px 24px);

        @include media('<=md') {
          padding: rem(0px);
        }
        .ui-kit-card-content {
          padding: rem(24px 20px);
        }
      }
      &-card-title {
        .ant-divider {
          margin: 12px 0px;
        }
      }
    }
  }
}
