@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

.OnboardingModuleStep {
  &-container {
    display: flex;
    align-items: center;
    gap: rem(16px);
    background-color: map.get($colors, 'white');
    border-radius: rem(8px);
    padding: rem(6px 12px);
    min-height: rem(64px);
    pointer-events: none;
    $root: &;
    $color-secondary: map.get($colors, 'secondary', 'main');

    &-complete {
      cursor: initial;
      #{$root}__checkbox {
        color: $color-secondary;
        background-color: color.change($color-secondary, $alpha: 0.1);
        border: none;
      }
      #{$root}__content-title {
        text-decoration: line-through;
        color: map.get($colors, 'textGray', 'dark') !important;
      }
    }

    &-active {
      border: 1px solid color.change($color-secondary, $alpha: 0.5);
      box-shadow: 2px 2px 16px color.change($color-secondary, $alpha: 0.25);
      pointer-events: initial;
      cursor: pointer;
    }

    &__checkbox {
      width: rem(20px);
      height: rem(20px);
      border-radius: rem(2px);
      border: 1px solid map.get($colors, 'textLight', 'light');
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: rem(6px);

      &-title {
        color: map.get($colors, 'textDark', 'dark') !important;
        font-weight: $font-weight-semibold;
        font-size: $font-size-large;
        @include media('<md') {
          font-size: $font-size-small;
        }
      }

      &-description {
        color: map.get($colors, 'textGray', 'light') !important;
        font-weight: $font-weight-semibold;
        @include media('<md') {
          font-size: $font-size-caption;
        }
      }
    }
  }
}
