@import 'styles/all.scss';

.StepWizard {
  display: flex;
  flex-direction: column;

  &-steps {
    display: flex;
    align-items: center;
    position: relative;

    .ui-kit-button {
      position: absolute;
      left: 0;
      top: 0;
    }

    &-content {
      width: rem(180px);
      margin: rem(0px auto 24px auto);
      @include media('<md') {
        width: rem(116px);
        margin: rem(0px auto 12px auto);
      }
    }
  }

  &-form {
    flex: 1;
    display: flex;
  }
 
}

.StepWizard-BtnCtn{
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  &_btn{
    width: 25%;
  }
}

