@import 'styles/all.scss';

.Overview_content {
  min-height: 100vh;
  padding: rem(48px 24px);
  margin-top: rem(70px);

  @include media('<=md') {
    padding: rem(24px 12px);
  }
  &.masquerading {
    margin-top: rem(35px);
  }
  .Overview_banner {
    margin-top: rem(-12px);
    margin-bottom: rem(24px);
  }
}
