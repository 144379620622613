@use 'sass:map';
@import 'styles/all.scss';

.StoreBaseForm {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: rem(40px);

  @include media('<lg') {
    gap: rem(24px);
  }

  &-content {
    overflow: auto;
    flex: 1 1 150px;
  }

  &-section-one {
    display: grid;
    grid-template-columns: 1fr 262px;
    gap: rem(0px 24px);

    @include media('<lg') {
      grid-template-columns: 1fr;
      gap: rem(24px 0px);
    }

    .ui-kit-form-control {
      margin: 0px;
    }

    &__details {
      display: flex;
      flex-direction: column;
      gap: rem(24px 0px);

      &-helperText {
        color: map.get($colors, 'textGray', 'dark') !important;
        .ui-kit-button {
          padding: 0px;
        }
      }
    }

    &__address{
      .ui-kit-form-control:first-child {
        width: 134px;

        @include media('<md') {
          width: 100%;
        }
      }
    }

    &__address,
    &__street {
      display: flex;
      gap: rem(16px);

      @include media('<md') {
        flex-direction: column;
      }

      .ui-kit-form-control:last-child {
        flex: 1 0 140px;

        @include media('<md') {
          flex: 1;
        }

        .ant-select {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    &__street {
      .ui-kit-form-control:first-child {
        flex-grow: 2;
      }
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    gap: rem(14px);
    min-width: rem(235px);
    margin: 0 auto;
  }
}
