@use 'sass:color';
@import 'styles/all.scss';

.ui-kit-radio-group {
  &-horizontal {
    .ui-kit-radio {
      display: inline-block;
      &:not(:last-child) {
        margin-right: rem(24px);
      }
    }
  }

  &-vertical {
    .ui-kit-radio {
      display: block;
      &:not(:first-child) {
        margin-top: rem(12px);
      }
    }
  }

  .ui-kit-radio.ant-radio-wrapper {
    word-wrap: break-word; /* Ensure text wraps properly */
    word-break: break-word;
    white-space: normal; /* Allow text to wrap */

    span:not(.ant-radio) {
      vertical-align: text-bottom;
    }
    .ant-radio-inner {
      width: rem(24px);
      height: rem(24px);
      margin-right: rem(4px);
    }
    .ant-radio-inner::after,
    .ant-radio-checked::after {
      background-color: $color-main-gray;
      top: rem(7px);
      left: rem(7px);
    }

    .ant-radio-checked .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $color-main-gray;
    }
  }
}
