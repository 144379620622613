@import 'styles/all.scss';

.page-title {
    font-size: rem(30px);
    line-height: rem(34px);
    // font-family: $font-helvetica;
    font-weight: $font-weight-bold;
    margin: rem(10px) 0 rem(25px) rem(10px);
}

.filters-box {
  background-color: white; 
  padding: rem(30px); 
  border-radius: rem(20px);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 50% !important;
}

.ant-picker-cell .ant-picker-cell-inner{
  min-width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  border-radius: 50% !important;


  &::before{
    border-radius: 50%;
  }
}