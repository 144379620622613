@use 'sass:map';
@import 'styles/all.scss';

.ui-kit-tabs {
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: map.get($colors, 'secondary', 'main');
  }

  .ant-tabs-nav {
    &::before {
      border-bottom: none;
    }
  }
  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: map.get($colors, 'textLight', 'dark');
    }
    &-active {
      .ant-tabs-tab-btn {
        color: map.get($colors, 'secondary', 'main');
      }
    }
  }
}
