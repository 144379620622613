@use 'sass:map';
@import 'styles/all.scss';

.OnboardingModule {
  &-container {
    width: 100%;
    max-width: map.get($breakpoints, 'xxl');
    margin: rem(16px auto 0px);
    padding: rem(0px 16px);
    @include media('>xxl') {
      margin: rem(0px auto);
      padding: rem(16px 0px);
    }
  }
  &-collapsable-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-size: rem(30px);
      font-weight: $font-weight-bold;
      .ui-kit-button {
        padding: 0px !important;
      }
      &-collapsed {
        font-size: $font-size-small !important;
        font-weight: $font-weight-semibold;
        @include media('<md') {
          font-size: $font-size-caption !important;
        }
      }

      @include media('<md') {
        font-size: rem(20px);
      }
    }
  }

  &-collapsable-content {
    display: flex;
    flex-direction: column;
    gap: rem(24px);
  }
}
