@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

.CardListItem {
  transition: all 0.3s ease-in-out;
  border: 2px solid transparent;
  box-shadow: none !important;
  display: flex;
  gap: rem(16px);
  flex-direction: row;
  padding: rem(10px);
  align-items: flex-start;

  $color-primary-main: map.get($colors, 'primary', 'main');
  $root: &;

  &-own-product {
    grid-column: 1/-1;
    margin: rem(0px auto);
    align-items: center;
    @include media('<lg') {
      margin: 0;
    }
  }

  &-selected {
    border: 2px solid $color-primary-main;
    #{$root}-image__content {
      background-color: color.change($color-primary-main, $alpha: 0.05);
      color: color.change($color-primary-main, $alpha: 0.5);
    }
  }
  &:hover {
    box-shadow: 2px 2px 16px rgba(174, 174, 174, 0.25) !important;
  }

  &-image {
    &__container {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      height: rem(90px);
      width: rem(122px);
      background-color: $color-nude;
      color: map.get($colors, 'textLight', 'dark');
      display: inline-flex;
      align-items: center;
      justify-content: center;

      @include media('<md') {
        height: rem(35px);
        width: rem(35px);
      }

      &-icon {
        height: rem(50px);
        width: auto;
        @include media('<md') {
          height: rem(20px);
        }
      }

      &-image {
        width: rem(122px);
        height: rem(90px);
        border-radius: rem(2px);
        @include media('<md') {
          width: rem(47px);
          height: rem(35px);
        }
        img {
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  &-content {
    .ui-kit-card-header {
      // unset display grid
      display: block;
      padding: rem(0px);
      &__content {
        gap: rem(2px);
        min-height: fit-content;
        &-title {
          @include media('<md') {
            font-size: $font-size-regular;
          }
        }
        &-subheader {
          font-size: $font-size-caption;
        }
      }
    }

    .ui-kit-card-content {
      padding: 0px;
      margin-top: rem(8px);
      & > p {
        @include media('<md') {
          font-size: $font-size-caption;
        }
      }
    }

    &__description {
      margin-top: rem(8px);
      @include media('<md') {
        font-size: $font-size-caption;
      }
    }
  }

  &-footer {
    text-transform: uppercase;
  }
  .ui-kit-card-actions {
    flex: 1;
  }
}
