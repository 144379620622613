@use 'sass:map';
@import 'styles/all.scss';

@mixin get-typography-by-color($root) {
  $array-colors: 'textGray', 'textDark', 'textLight', 'secondary', 'primary';
  @each $color in $array-colors {
    @include unify-parent($root + '-' + $color) {
      color: map.get($colors, $color, 'dark');
    }
  }
}

@mixin trim-typography-by-rows($root) {
  $array-rows: 1, 2, 3, 4;
  @each $row in $array-rows {
    @include unify-parent($root + '-rows-' + $row) {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      white-space: normal;
      -webkit-line-clamp: $row;
      -webkit-box-orient: vertical;
    }
  }
}

.ui-kit-typography {
  @include get-typography-by-color(&);
  @include trim-typography-by-rows(&);

  letter-spacing: 0.00938em;
  &-body1 {
    font-size: $font-size-regular;
    font-weight: $font-weight-regular;
    line-height: 1.5;
  }

  &-body2 {
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    line-height: 1.43;
  }

  &-subtitle1 {
    font-size: $font-size-regular;
    font-weight: $font-weight-regular;
    line-height: 1.75;
  }

  &-subtitle2 {
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    line-height: 1.57;
  }

  &-caption {
    font-size: $font-size-caption;
    font-weight: $font-weight-regular;
    line-height: 1.66;
  }
}
