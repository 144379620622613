@use 'sass:color';
@import 'styles/all.scss';

.Checkbox.ant-checkbox-wrapper {
  &:hover {
    border-color: $color-primary-main;
  }
  &:hover .ant-checkbox-inner {
    border-color: $color-primary-main;
  }

  .ant-checkbox {
    border-color: $color-primary-main;
    width: rem(28px);
    height: rem(28px);
  }

  .ant-checkbox:hover {
    border-color: $color-primary-main;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $color-primary-main;
    border-color: $color-primary-main;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $color-primary-main;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $color-primary-main;
  }

  .ant-checkbox-checked::after {
    border-color: $color-primary-main;
  }

  .ant-checkbox-input {
    transform: scale(1.75);
    transform-origin: 62.5% 62.5%;
  }

  .ant-checkbox-inner {
    width: 100%;
    height: 100%;
    border-radius: rem(3px);
  }

  .ant-checkbox-inner::after {
    width: rem(10.5px);
    height: rem(15.75px);
  }
}
