@use 'sass:color';
@use 'sass:map';
@import 'styles/all.scss';

.ui-kit-stepper {
  $root: &;
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
  }

  &-step {
    display: block;
    &__container {
      width: rem(16px);
      height: rem(16px);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid map.get($colors, 'textLight', 'light');
      border-radius: 50%;

      @include media('<md') {
        width: rem(13px);
        height: rem(13px);
      }

      &-active {
        $color-scondary-main: map.get($colors, 'secondary', 'main');
        border-color: $color-scondary-main;
        &::before {
          content: '';
          width: rem(4px);
          height: rem(4px);
          background-color: $color-scondary-main;
          border-radius: 50%;
          @include media('<md') {
            width: rem(3px);
            height: rem(3px);
          }
        }
      }
    }
  }

  &-connector {
    flex: 1;
    &__line {
      display: block;
      border-color: map.get($colors, 'textLight', 'light');
      border-top-style: solid;
      border-top-width: 2px;
      &-completed {
        $color-scondary-main: map.get($colors, 'secondary', 'main');
        background-color: $color-scondary-main;
        border-color: $color-scondary-main;
        color: map.get($colors, 'white');
      }
    }
  }
}
