@use 'sass:map';
@import 'styles/all.scss';

.ui-kit-input-label {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: $font-size-small;
  font-weight: $font-weight-semibold;
  line-height: rem(18px);
  margin-bottom: rem(4px);
  color: currentColor;

  &-asterisk {
    color: $color-red;
    font-size: $font-size-regular;
  }
}
