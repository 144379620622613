@use 'sass:color';
@use 'sass:map';

@import 'styles/all.scss';

.ui-kit-select.ant-select {
  $color-primary-main: map.get($colors, 'textDark', 'main');

  &:not(.ant-select-disabled) {
    cursor: pointer;
    .ant-select-selector {
      &:hover {
        .ant-select-selection-item,
        & ~ .ant-select-arrow {
          color: map.get($colors, 'primary', 'dark');
        }
      }
    }
  }

  .ant-select-selection-item {
    font-size: $font-size-small !important;
    color: $color-primary-main;
  }

  &.ant-select-disabled {
    .ant-select-selection-item {
      color: map.get($colors, 'textDark', 'dark');
    }
    .ant-select-arrow {
      display: none;
    }
  }

  .ant-select-arrow {
    color: $color-primary-main;
    top: 30%;
  }

  @at-root .ant-select-dropdown {
    min-width: fit-content !important;
    .ant-select-item {
      &-option {
        height: rem(56px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: $font-weight-semibold;
        &-active {
          background-color: color.change($color-primary-main, $alpha: 0.1);
        }
        &-selected {
          color: $color-primary-main;
          background-color: color.change($color-primary-main, $alpha: 0.05);
          border-left: 2px solid map.get($colors, 'primary', 'main');
        }
      }
    }
  }
}
