@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

$sider-width: rem(61px);

.Dashboard {
  overflow-x: hidden;
  position: relative;
  margin-top: 0;
  background-color: $color-background;
  margin-top: rem(70px);

  @include media('<=md') {
    margin-top: rem(56px);
  }

  &.masquerading {
    top: rem(35px);
  }

  .Dashboard_desktop {
    flex: 1;
    display: flex;
    flex-direction: column;
    .sider {
      height: 100vh;
      overflow: auto;
      position: fixed;
      border-right: rem(1px) solid map.get($colors, 'textLight', 'light');
      margin-bottom: rem(100px);
      transition: width 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      width: rem(61px);
      z-index: 10;
      background-color: map.get($colors, 'white');

      @include media('<=md') {
        display: none;
      }

      &:hover,
      &:focus {
        width: rem(286px);
        left: 0;
        & ~ .sider-backdrop {
          visibility: visible;
        }
      }

      &-backdrop {
        width: 100vw;
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        position: absolute;
        top: 0;
        visibility: hidden;
        pointer-events: none;
        z-index: 5;
      }

      .ant-layout-sider-children > .ant-row {
        & > .ant-col:last-child {
          margin-bottom: rem(80px);
        }
      }

      .ant-row,
      .ant-col,
      .ant-menu {
        height: 100%;
      }

      .ant-menu {
        border-right: none;
        background-color: map.get($colors, 'white');
        .ant-menu-item-only-child {
          padding-left: rem(60px) !important;
          & > button {
            justify-content: flex-start;
            font-weight: $font-weight-semibold;
            color: inherit;
          }
        }
      }

      .ant-menu-item,
      .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        height: rem(50px);
        & > svg {
          margin-right: rem(24px);
          color: $color-secondary-text;
        }
      }

      .ant-menu-item,
      .ant-menu-submenu-title {
        &:hover {
          color: $color-primary-main;
          background-color: rgba(45, 163, 151, 0.2);
        }
      }

      .ant-menu-submenu {
        min-width: rem(286px);

        &-selected {
          color: $color-primary-main;
          font-weight: $font-weight-bold;
          .ant-menu-submenu-arrow {
            color: $color-primary-main;
          }
        }
        &-active {
          .ant-menu-submenu-title {
            color: initial;
            background-color: transparent;
          }
          .ant-menu-submenu-arrow {
            color: initial;
          }
        }
        &-open {
          .ant-menu-submenu-title {
            color: initial;
            background-color: transparent;
          }
        }
      }

      .ant-menu-item,
      .ant-menu-submenu {
        font-weight: $font-weight-semibold;
        & > svg {
          margin-right: rem(24px);
          color: $color-secondary-text;
          min-width: rem(18px);
        }
        .hc-deactivate-store {
          padding: 0px !important;
        }
        &-selected {
          font-weight: $font-weight-bold;
          color: $color-primary-main;
          background-color: color.change(map.get($colors, 'primary', 'main'), $alpha: 0.1);
          & > svg {
            color: $color-primary-main;
          }
        }
        &-open {
          background-color: transparent;
        }
        &::after {
          left: 0;
          right: unset;
        }
      }
    }

    .sider.ant-layout-sider-dark,
    .menu.ant-menu-light {
      background-color: map.get($colors, 'white');
    }

    .Dashboard_layout {
      background-color: $color-background;
      margin-left: $sider-width;
      transition: all 0.2s;

      &.masquerading {
        margin-top: rem(35px);
      }

      @include media('<=md') {
        margin-left: 0;
      }
    }

    .Dashboard_header {
      background-color: inherit;
      height: fit-content;
    }

    .Dashboard_content {
      padding: rem(24px 0px 48px);
      background-color: $color-background;
      overflow: initial;
    }

    .Dashboard_footer {
      background-color: $color-background;
      border-top: rem(1px) solid map.get($colors, 'textLight', 'light');
      height: rem(100px);
      padding: 0;
    }
  }

  .ant-layout-header {
    padding: 0;
    line-height: 22px;
  }
}
