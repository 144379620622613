@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

@mixin active-color($color) {
  border-color: $color;
  outline-color: $color;
  box-shadow: rem(0 0 0 2px) color.change($color, $alpha: 0.4);
}

.ui-kit-input {
  font-weight: $font-weight-light;
  font-size: $font-size-small;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  $root: &;

  &:after {
    color: #00000073;
    white-space: nowrap;
    content: attr(data-count);
    pointer-events: none;
    position: absolute;
    top: rem(-22px);
    right: rem(0px);
    color: map.get($colors, 'textDark', 'dark');
    font-weight: $font-weight-light;
    font-size: $font-size-caption;
  }

  fieldset {
    text-align: left;
    position: absolute;
    inset: 0px;
    margin: 0px;
    padding: 0px 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: map.get($colors, 'textLight', 'light');
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &-disabled {
    fieldset {
      background-color: color.change(map.get($colors, 'textLight', 'light'), $alpha: 0.5);
      border: 1px solid map.get($colors, 'textLight', 'light') !important;
    }
  }

  &__startAdornment {
    padding-left: rem(16px);
    flex-direction: row;
  }

  &__endAdornment {
    padding-right: rem(16px);
    flex-direction: row-reverse;
    #{$root}-adornment {
      margin-right: rem(-12px);
    }
  }

  &-field {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    border: 0px;
    box-sizing: content-box;
    background: none;
    min-height: rem(40px);
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: rem(5px 16px);
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
      display: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
      appearance: none;
    }

    &__startAdornment {
      padding: rem(5px 16px 5px 0px);
      margin-left: rem(6px);
    }

    &__endAdornment {
      padding: rem(5px 0px 5px 16px);
      margin-right: rem(4px);
    }
  }

  &-active {
    $color: map.get($colors, 'primary', 'main');
    &:hover:not(&-disabled) {
      fieldset {
        @include active-color($color);
      }
    }
    #{$root}-field:focus {
      & + fieldset {
        border: 2px solid $color;
      }
    }
  }

  &-error {
    $color: map.get($colors, 'error', 'main');
    &:hover:not(&-disabled) {
      fieldset {
        @include active-color($color);
      }
    }
    #{$root}-field:focus {
      & + fieldset {
        border: 2px solid $color;
      }
    }
    fieldset {
      border: 2px solid $color;
    }
  }
}
