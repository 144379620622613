@import 'styles/all.scss';

.hc-confirm-modal {
  min-width: rem(300px);

  .ant-modal-body {
    padding: rem(60px 60px 30px 60px);
    // font-family: $font-helvetica;
    font-size: rem(24px);
    line-height: 142%;
    text-align: center;
    color: $color-main-gray;
    @include media('<=md') {
      // font-family: $font-spartan;
      font-size: rem(14px);
      line-height: 171%;

      margin: rem(0 15px);
      padding: rem(60px 30px 20px 30px);
    }
  }

  .ant-modal-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: rem(25px);
    border: 0;
    padding: rem(0 60px 60px);

    @include media('<=md') {
      padding: rem(0 30px 45px);
      gap: rem(15px);
    }

    .ant-btn {
      display: block;
      height: rem(45px);

      &.confirm {
        background: $color-red;
        color: $color-white;
        &:hover {
          background: $color-white;
          color: $color-red;
          border: none;
        }
      }

      &.cancel {
        background: transparent;
        color: $color-primary-main;
        &:hover {
          color: $color-white;
          background: $color-primary-main;
          border: none;
        }
      }
    }
  }
}
